.nav-item {
    font-size: 16px;
}

html {
    font-size: 14px;
}

/*
 * Normally we have left buttons and right buttons using a RightDivider from
 * spider, but that depends on Modal.Actions being flex.
 */
.ui.modal > .actions {
    display: flex;
}

/*
 * Custom icons set on dropdown suddenly lose the float right, since in
 * semantic-ui the class selector is really .dropdown.icon (so if you use a
 * keyboard icon it doesn't work anymore).
 */
.ui.form .field>.selection.dropdown>.icon {
    float: right;
}

.ui.disabled.input > input:disabled {
    opacity: 1 !important; /* this will get the 0.45 opacity twice otherwise and thus become unreadable */
}
