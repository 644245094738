@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?0af4ff5dd675e41aaf0aa9dca1b03790?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?0af4ff5dd675e41aaf0aa9dca1b03790") format("woff2"),
url("./cy-custom-icons.woff?0af4ff5dd675e41aaf0aa9dca1b03790") format("woff"),
url("./cy-custom-icons.ttf?0af4ff5dd675e41aaf0aa9dca1b03790") format("truetype"),
url("./cy-custom-icons.svg?0af4ff5dd675e41aaf0aa9dca1b03790#cy-custom-icons") format("svg");
}

i.icon.cy,
i.icon.route-km-dev,
i.icon.travis-camera-icon,
i.icon.travis-electric-icon,
i.icon.travis-fence-icon,
i.icon.travis-guard-icon,
i.icon.travis-hotel-icon,
i.icon.travis-parking-icon,
i.icon.travis-toilet-icon,
i.icon.travis-trailer-icon {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy:before {
    content: "\f101";
}
i.icon.route-km-dev:before {
    content: "\f102";
}
i.icon.travis-camera-icon:before {
    content: "\f103";
}
i.icon.travis-electric-icon:before {
    content: "\f104";
}
i.icon.travis-fence-icon:before {
    content: "\f105";
}
i.icon.travis-guard-icon:before {
    content: "\f106";
}
i.icon.travis-hotel-icon:before {
    content: "\f107";
}
i.icon.travis-parking-icon:before {
    content: "\f108";
}
i.icon.travis-toilet-icon:before {
    content: "\f109";
}
i.icon.travis-trailer-icon:before {
    content: "\f10a";
}
